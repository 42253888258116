
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Regular'), url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'MontserratBold';
  src: local('Montserrat-Bold'), url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 400;
}
body{
  font-family: 'Montserrat' !important;
  overflow-x: hidden;
}

#form-input-control-email, #form-input-control-password{
box-shadow: 5px 5px 5px #03435a;
}

.spinner-button {
  position: relative;
}

.spinner-button::before {
  content: "";
  position: absolute;
  top: 25%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .recovery-password-form {
    width: 100%;
  }
}

.main-img-card-dashboard{
  position: relative;
}
.main-img-card-dashboard:hover{
  position: absolute;
}

.general-typography-classname{
  font-size: 1rem !important;
}
@media screen and (max-width: 767px) {
  .general-typography-classname{
    font-size: 0.8rem !important;
  }
}