.ui.form.contact-form {
  color: #ffffff;
  width: 100%;
}
.ui.form .field > label {
  color: #ffffff !important;
  font-size: 1.1rem !important;
}
.ui.form .field > div {
  margin-bottom: 20px;
}
.ui.blue.button {
  margin-top: 20px;
}

#form-input-control-email{
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .ui.form.contact-form {
    color: #ffffff;
    width: 70%;
    text-align: center;
  }
}
