.contact-form-agregar-fase-jornada .ui.search.dropdown {
  width: 47%;
}

.contact-form-agregar-fase-jornada.ui.form .field {
  width: 47%;
}
.contact-form-agregar-fase-jornada{
    display: flex;
    flex-wrap: wrap;
}