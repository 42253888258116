.contact-form-agregar-resultados {
    padding-top: 30px;
  }
.contact-form-agregar-resultados .ui.search.dropdown {
  width: 30%;
  height: fit-content;
  background-color: #0082e5 !important;
  border-radius: 5px;
}
.contact-form-agregar-resultados .nivel-superior-checkbox{
  width: 8%;
  order: 1;
}
@media screen and (max-width: 767px) {
  .contact-form-agregar-resultados .nivel-superior-checkbox{
    order: 2;
  }
}

.contact-form-agregar-resultados .field {
  width: 80% !important;
  border-radius: 5px;
  margin-bottom: 10px !important;
}
.contact-form-agregar-resultados .field .input input {
  background-color: #0082e5 !important;
  width: 100% !important;
}
.contact-form-agregar-resultados .field .ui.input input {
  color: #ffffff;
}

.contact-form-agregar-resultados .ui.disabled.search {
  margin-bottom: 20px;
}

.contact-form-agregar-resultados #cargar-resultados-sets-1 .field{
    width: 13% !important;
}
.contact-form-agregar-resultados #cargar-resultados-sets-2 .field{
    width: 13% !important;
}
.contact-form-agregar-resultados #cargar-resultados-sets-3 .field{
    width: 13% !important;
}
.contact-form-agregar-resultados #cargar-resultados-sets-4 .field{
    width: 13% !important;
}
.contact-form-agregar-resultados #cargar-resultados-sets-5 .field{
    width: 13% !important;
}
