.loader {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(255, 255, 255, 0.2);
    border-right: 5px solid rgba(255, 255, 255, 0.2);
    border-bottom: 5px solid rgba(255, 255, 255, 0.2);
    border-left: 5px solid #ffffff;
    transform: translateZ(0);
    animation: loader 1.1s infinite linear;
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
