.caroussel > button {
  display: block !important;
  color: #ffffff;
}

.caroussel .caroussel-dots {
  display: none !important;
}

.caroussel-content [class*="col-"] {
  display: flex;
  place-content: center;
}
