.contact-form-gestionar-equipos {
  display: flex;
  align-items: end;
}

.ui.disabled.search {
  margin: 0;
}
.ui.form .field {
  margin: 0;
  /*width: 45%;*/
}
#select-category-gestionar-usuarios {
  margin: 15px;
  width: 45%;
}
.contact-form-gestionar-equipos {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
.ui.search.dropdown {
  margin-top: 0 !important;
}
.buscar-equipo-player:hover {
  color: #ffa436 !important;
}
.buscar-equipo-tablecell-name {
  color: #ffffff !important;
  border-bottom: none !important;
  cursor: pointer !important;
}
.buscar-equipo-tablecell-name:hover {
  color: #ffa436 !important;
}
#contact-form-gestionar-equipos .field{
  width: 50%;
}
#contact-form-gestionar-equipos .field >label{
  width: 50%;
  font-family: "Montserrat";
  font-size: 1rem !important;
}