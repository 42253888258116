.calendario-alineacion-row:hover {
  color: #ffa436 !important;
}
.calendario-alineacion-row {
  cursor: pointer;
}
.contact-form-agregar-alineacion {
  padding-top: 50px;
}

.contact-form-agregar-alineacion .ui.search.dropdown {
  width: 30%;
  height: fit-content;
  background-color: #0082e5 !important;
  border-radius: 5px;
}

.contact-form-agregar-alineacion .field {
  width: 30% !important;
  border-radius: 5px;
  margin-bottom: 0 !important;
}
.contact-form-agregar-alineacion .field .input input {
  background-color: #0082e5 !important;
}
.contact-form-agregar-alineacion .field .ui.input input {
  color: #ffffff;
}

.contact-form-agregar-alineacion .ui.disabled.search {
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .contact-form-agregar-alineacion .field {
    width: 45% !important;
  }
}
@media screen and (max-width: 767px) {
  .contact-form-agregar-alineacion .ui.search.dropdown {
    width: 100%;
  }
}
