.perfil-usuario-category {
    background-color: #ffa436 !important;
    width: 25%;
    margin-bottom: 30px;
  }
  .perfil-usuario-category .text {
    color: #ffffff !important;
  }
  
  .perfil-usuario-category .menu .item {
    background-color: #ffa436 !important;
  }
  .perfil-usuario-category .icon {
    color: #ffffff !important;
  }
  .ui.selection.dropdown {
    box-shadow: 3px 3px 3px #034360 !important;
  }
  #form-input-control-picture-modificar {
    background-color: #03438a;
    border: none;
    font-size: 0.7rem;
    width: 110px;
  }
  #form-input-control-picture-modificar input {
    background-color: #03438a;
    border: none;
  }
  .contact-form-modificar-user .required {
    margin-bottom: 0;
  }
  .ui.blue.button {
    margin-top: 0 !important;
    margin-bottom: 50px;
    /*padding: 0;*/
  }
  .contact-form-modificar-user {
    text-align: center;
  }
  .ui.form .field .input {
    margin-bottom: 0;
  }
  .contact-form-modificar-perfil,
  .contact-form-gestionar-usuarios {
    display: flex;
    flex-wrap: wrap;
  }
  .contact-form-modificar-perfil > div {
    width: 47%;
    margin-left: 10px !important;
  }
  
  .contact-form-gestionar-usuarios > div {
    width: 47%;
    margin-left: 10px !important;
  }
  
  .ui.search.dropdown {
    margin-bottom: 20px;
    background-color: #ffa436 !important;
    color: #ffffff !important;
    width: 45%;
    margin-top: 10px !important;
  }
  .ui.dropdown:not(.button)>.default.text {
    color: #ffffff !important;
  }
  .ui.toggle.checkbox{
    margin-top: 20px;
  }
  
  #modificar-usuarios .ui.form .field{
    width: 47%;
  }
  
  #modificar-usuarios #contact-form-modificar-user .field{
    width: 100%;
  }
  #gestionar-usuarios .ui.search.dropdown{
    width: 47%;
    margin: 0;
  }
  #form-input-control-administracion{
    width: 50%;
  }
  